import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
/*------- Sub Menu --------*/
import NavBarPart from "../main/navbar";

//constraint
const primaryColor = "#e91e63";
const secondaryColor = "#ed4b82";
const fontColor = "white";

const breadcrumbs = [
  <Link underline="hover" sx={{ display: "flex", alignItems: "center", color: fontColor }} href="/">
    <HomeIcon sx={{ mr: 0.5, color: fontColor }} fontSize="inherit" color={fontColor} />
    Main
  </Link>,
  <Typography key="3" sx={{ color: fontColor }}>
    Policy
  </Typography>,
];

export default function PolicyFunction() {
  return (
    <Box sx={{ mt: -6 }}>
      <Container sx={{ pb: 15 }}>
        {/* Breadcrumb */}
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pt: 2, mb: 4, color: fontColor }}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* End: Breadcrump */}
        {/* Policy Part */}
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Policy
        </Typography>
        {/* End: Policy Part */}

        {/* Policy Text */}
        <Grid container lg={12} mt={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={8} sx={{ display: "flex" }} mt={3}>
            <Typography variant="subtitle1">
              focusonyoungjae does not track any Persional Indentifiable Information of visitors.
              <br></br>
              None of the data used by focusonyoungjae is stored or collectied anywhere, and not shared with any third parties.
              <br></br>
              <br></br>
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                LINK
              </Typography>
              This website contains links to other websites. I am not responsible for the privacy practices of other websites. As a citizen of the Internet, I strongly encourage you to read the privacy policy (or privacy statement) of each and every website you visit, as this privacy policy applies solely to this website.
              <br></br>
              <br></br>
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                DISCLAIMER
              </Typography>
              All information on this website is provided for general information purposes only. Nothing on this site constitutes medical, legal, financial, or other professional advice on any subject matter.
            </Typography>
            <br></br>
          </Grid>
        </Grid>

        {/* End: Policy Text */}
      </Container>
    </Box>
  );
}
