import * as React from "react";
import { Button, Fab, Grid, IconButton, List, ListItem, Menu, MenuItem, Paper, Tabs, Tooltip, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Image } from "mui-image";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import { CardActionArea } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import HeadsetIcon from "@mui/icons-material/Headset";
import AOS from "aos";

//css
import "../../css/errday.css";
import "@splidejs/react-splide/css";
import "aos/dist/aos.css";
import "@fontsource/prompt";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const primaryColor = "#fbe672";
const secondaryColor = "#17130c";
const thirdColor = "white";
const iconColor = "#c1b362";
const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const fabstyle = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};
export default function ErrrdayFunction() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  AOS.init();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor: primaryColor }}>
          <Image src="./assets/errrday/header.jpg" fit="contain" easing="ease-in-out" duration={1200} />
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            backgroundColor: primaryColor,
            backgroundSize: "cover",
            height: 100,
          }}
        >
          <Image src="./assets/errrday/logo.png" fit="contain" height="100%" />
        </Box>

        <Tabs centered sx={{ backgroundColor: primaryColor }}>
          <Box sx={{ flex: "center", display: "flex", padding: 0, flexDirection: "row" }}>
            <List style={flexContainer}>
              <ListItem>
                {/* Youngjae Official */}
                <Tooltip title="YOUNGJAExArs" placement="top">
                  <IconButton
                    size="small"
                    aria-label="YOUNGJAExArs"
                    href="https://twitter.com/YOUNGJAExArs"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>

                {/* Youngjae Personal Twitter Account */}
                <Tooltip title="@GOTYJ_Ars_Vita" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@GOTYJ_Ars_Vita"
                    href="https://twitter.com/GOTYJ_Ars_Vita"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>
                {/* YOUTUBE */}
                <Tooltip title="@YoungjaexArs" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@YoungjaexArs"
                    href="https://www.youtube.com/@YoungjaexArs"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <YouTube />
                  </IconButton>
                </Tooltip>

                {/* INSTAGRAM */}
                <Tooltip title="@333cyj333" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@333cyj333"
                    href="https://www.instagram.com/333cyj333"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Tabs>

        <Splide
          aria-label="Youngjae Teaser Image"
          options={{
            rewind: true,
            // height: 1200,
            type: "loop",
            gap: "1rem",
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            fixedHeight: 1000,
            autoHeight: true,
            cover: true,
          }}
        >
          <SplideSlide>
            <img src="./assets/errrday/teaser1.jpg" alt="Teaser 1" />
          </SplideSlide>
          <SplideSlide>
            <img src="./assets/errrday/teaser2.jpg" alt="Teaser 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="./assets/errrday/teaser3.jpg" alt="Teaser 3" />
          </SplideSlide>
          <SplideSlide>
            <img src="./assets/errrday/teaser4.jpg" alt="Teaser 4" />
          </SplideSlide>
          <SplideSlide>
            <img src="./assets/errrday/teaser5.jpg" alt="Teaser 5" />
          </SplideSlide>
        </Splide>

        <Box height={350} sx={{ backgroundColor: primaryColor, paddingTop: "7rem" }}>
          <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: secondaryColor, textAlign: "center" }}>
              영재(Youngjae)<br></br>Digital Single 'Errr Day' <br></br>
              <br></br>2023.03.12(SUN) <br></br>6PM(KST)
            </Typography>
          </div>
        </Box>

        {/* Music Video */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Music Video
          </Typography>
          <Grid container spacing={5} padding={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="720" height="1280" src="https://www.youtube.com/embed/TMzfGRk9Voo" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Music Video"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled">영재(Youngjae) 'Errr Day' MV</Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {/* Moode Film */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Mood Film Image
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <img src="./assets/errrday/day.jpg" style={{ height: "500px" }} alt="Mood Film Day Version"></img>
              </div>
              <Typography variant="subtitled">Day Version</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <img src="./assets/errrday/night.jpg" style={{ height: "500px" }} alt="Mood Film Night Version"></img>
              </div>
              <Typography variant="subtitled">Night Version</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <img src="./assets/errrday/lyric_teaser.jpg" style={{ height: "500px" }} alt="Lyric Teaser"></img>
              </div>
              <Typography variant="subtitled">Lyric Teaser</Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Teaser Video */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Mood Film Video
          </Typography>

          <Grid container spacing={5} padding={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="1280" height="1000" src="https://www.youtube.com/embed/0IhQOgahrx8" frameborder="0" allowfullscreen allow="autoplay" title="Day Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled">영재(Youngjae) 'Errr Day' Mood Film (Day ver.)</Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive ">
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/AHgVAqx4Hac" frameborder="0" allowfullscreen allow="autoplay" title="Night Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled">영재(Youngjae) 'Errr Day' Mood Film (Night ver.)</Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="720" height="1280" src="https://www.youtube.com/embed/SXaGtfonOvU" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Lyric Teaser"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled">영재(Youngjae) 'Errr Day' Lyric Teaser</Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="720" height="1280" src="https://www.youtube.com/embed/zWWb07pliO8" frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Teaser MV"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled">영재(Youngjae) 'Errr Day' MV Teaser</Typography>
              </div>
            </Grid>
          </Grid>
        </Box>

        {/* Listen Now */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "3rem" }}>
          <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              Listen Now
            </Typography>
            <Grid container spacing={2} padding={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "center" }}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="http://kko.to/502ZiBkFVH" target="_blank">
                    Melon
                  </Button>{" "}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="www.genie.co.kr/TDFNA2" target="_blank">
                    Genie Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://www.music-flo.com/detail/album/eandhoioa/albumtrack" target="_blank">
                    FLO Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.bugs.co.kr/album/4084475" target="_blank">
                    Bugs Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://vibe.naver.com/album/9286824" target="_blank">
                    Naver Vibe
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://open.spotify.com/album/7uoPw6yndoEeatuxKzacnK" target="_blank">
                    Spotify
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.youtube.com/playlist?list=OLAK5uy_ldgicNgmx1RcGqtkMOqGvKGvZ_cVwK8yY" target="_blank">
                    Youtube Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.apple.com/us/album/errr-day-single/1676871247" target="_blank">
                    Apple Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.apple.com/th/album/errr-day-single/1676871247" target="_blank">
                    iTunes Store
                  </Button>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://tidal.com/browse/album/281497073" target="_blank">
                    Tidal Music
                  </Button>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.apple.com/us/browse" target="_blank">
                    Apple Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="#" target="_blank">
                    iTunes Music
                  </Button>
                </Stack>
              </Grid> */}
            </Grid>
          </div>
        </Box>

        {/* Videos */}

        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "5rem" }}>
          <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              Videos
            </Typography>
            <Grid container spacing={4} style={{ display: "flex", padding: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://youtu.be/tVVcpJWopmY" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/behind.jpg" alt="Jacket & Behind The Scenes" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [Behind]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재(Youngjae) - [Errr Day] Jacket & MV Behind The Scenes
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* Cheering Guide */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://youtu.be/nsZKxw6V3tw" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/cheering_guide.jpg" alt="Errr Day Cheering Guide" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Cheering Guide
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재(Youngjae) - [Errr Day] Cheering Guide 💚
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* 1TheK Special Clip */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://youtu.be/74TdwxJ7QJo" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/20230318_74TdwxJ7QJo.jpg" alt="" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Special Clip
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재 _ Errr Day | 스페셜클립 | Special Clip | 라이브 | LIVE | 4Kㅣ영재 GOT7
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* 1TheK Special Clip: END */}

              {/* Pungja Loves Alcohol */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://youtu.be/F4hPfkHA4Bk" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/1679045651_0.jpg" alt="" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        풍자愛술
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        [Pungja L♥ves Alcohol EP.5] GOT7 Youngjae is here to drink! His 10 years love goes on Errr Day!
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* Pungja Loves Alcohol:END */}

              {/* Dingo Music */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://youtu.be/9SZQKfn4jyw" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/20230322_9SZQKfn4jyw.jpg" alt="" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Dingo Music
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        [FOCUS] 영재(Young Jae) – Errr Day | 딩고뮤직 | Dingo Music
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* Dingo Music:END */}
            </Grid>
          </div>
        </Box>

        {/* Article */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "5rem" }}>
          <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              Articles
            </Typography>
            <Grid container spacing={4} style={{ display: "flex", padding: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="https://naver.me/xsY6UO0U" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/naver.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [+EVENT]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        Errr Day 보고 싶은 영재의 미공개 사진
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card>
                  <CardActionArea href="kko.to/nK2IsuGprr" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/melon_3.jpg" alt="Melon Behind Cut" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Melon Behind Cut
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        설렘 가득한 봄 노래, 영재 (Youngjae) [Errr Day]
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>
        {/* News Part */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "5rem" }}>
          <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              News
            </Typography>
            <Grid container spacing={4} style={{ display: "flex", padding: "1rem" }}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/xyjBLp5Y" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_1.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재, 12일 컴백 예고..'Errr Day' 커밍순 티저 오픈
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* New2 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/F4tdg72a" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_2.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재, 새 디지털 싱글 'Errr Day'…첫 번째 콘셉트 포토 공개
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* New3 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/52hEUVwk" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_3.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        ˙봄 청량→장꾸미˙ 갓세븐 영재, ˙Errr Day˙ 콘셉트 포토 공개
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* NEWS 4 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/5YwLXBgy" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_4.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재, 싱글 ‘Errr Day’ 무드 필름 공개..휴양지 속 청량 에너지
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* NEWS 5 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/GYTUyrgU" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_5.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재 ‘Errr Day’ Night 버전 무드 필름 공개, 설렘 담았다
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* NEWS 6 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/ID19kqD9" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_6.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재 "신곡 ‘Errr Day’, 부모님 사랑 이야기 참고" [일문일답]
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              {/* News 7 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/FrluTQEp" target="_blank">
                    <CardMedia component="img" image="./assets/errrday/news_7.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재, 봄 닮은 'Errr Day' 발매.. 작사·작곡 참여
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>

        {/* <Fab color="primary" aria-label="top" style={fabstyle} onClick={handleClick}>
          <ArrowDropUpIcon />
        </Fab> */}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
