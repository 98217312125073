import React from "react";
import ReactDOM from "react-dom/client";
import "react-app-polyfill/stable";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import "@fontsource/prompt";
import "@fontsource/noto-sans-kr";
// import { Route, Routes } from "react-router";
// import { BrowserRouter } from "react-router-dom";

// component

// import ToggleColorMode from "./components/main/toggleTheme";
// import { Divider } from "@mui/material";
import MenuBar from "./components/main/menubar";
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: indigo[500],
//     },
//     secondary: {
//       main: "#14a8c2",
//     },
//   },
//   typography: {
//     fontFamily: ["Prompt", "Noto Sans KR"].join(","),
//   },
// });

// const languageOptions = [
//   { key: "English", text: "English", value: "EN" },
//   { key: "Thai", text: "ภาษาไทย", value: "TH" },
// ];

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MenuBar />
  </React.StrictMode>
);
