import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Avatar, Badge, Box, Breadcrumbs, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Collapse, CssBaseline, Divider, Fab, FormControl, Grid, IconButton, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Paper, Select, Tab, Table, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Image } from "mui-image";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AOS from "aos";
// import axios from "axios";
import axios from "../instance/axios";
import { pink, yellow } from "@mui/material/colors";
import moment from "moment";
// SubMenu
import NavBarPart from "../main/navbar";

//icon menu
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import HeadsetIcon from "@mui/icons-material/Headset";
import LinkIcon from "@mui/icons-material/Link";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PersonIcon from "@mui/icons-material/Person";
import VideocamIcon from "@mui/icons-material/Videocam";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//css
import "aos/dist/aos.css";
import "../../css/bestfriend.css";
//constraint
const primaryColor = "#e91e63";
const secondaryColor = "#ed4b82";
const fontColor = "white";

const breadcrumbs = [
  <Link underline="hover" sx={{ display: "flex", alignItems: "center", color: fontColor }} href="/">
    <HomeIcon sx={{ mr: 0.5, color: fontColor }} fontSize="inherit" color={fontColor} />
    Main
  </Link>,
  <Typography key="3" sx={{ color: fontColor }}>
    Videos
  </Typography>,
];

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};
const buttonColor = yellow[600];
const baseURL = "https://www.focusonyoungjae.com/";
const apiURL = `${baseURL}/_youtube/list`;
const apiVideoListGroup = `${baseURL}/_youtube/listgroup`;
const videoType = [
  {
    value: "",
    label: "All Type",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "shorts",
    label: "Shorts",
  },
  {
    value: "mv",
    label: "MV",
  },
  {
    value: "music",
    label: "Music",
  },
  {
    value: "special",
    label: "Special",
  },
];
const fabstyle = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};

const tab = {
  overflow: "initial",
};
let arr_group_video = [];
let TabsInfo = [];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

//export function
export default function YoutubeFunction() {
  //call guest list

  const [videopost, setVideoPost] = React.useState("");
  const [totalpage, setTotalPage] = React.useState("");
  const [total_videos, setTotalVideo] = React.useState("");
  const [keywords, setKeywords] = React.useState("");

  //new start
  const [value, setValue] = React.useState(0);
  const [assignTabName, setAssignTabName] = React.useState("");
  const [assignTabPanel, setAssignTabPanel] = React.useState("");
  const [numTabs, setNumTabs] = React.useState("");
  const [dataGlobal, setData] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);

  let global_tab_num = 0;
  //on start web page
  React.useEffect(() => {
    functionAssignTabName(0, 1, "", "");
  }, []);

  const handleChangeTab = (e, newValue) => {
    setValue(newValue);
    setVideoType("");
    setKeywords("");
    functionAssignTabName(newValue, 1, "", "");
    console.log("New Tab Value 👉️ ", global_tab_num);
  };

  const handleChange = (event) => {
    setKeywords(event.target.value);
    console.log("value is:", event.target.value);
    console.log(keywords);
  };
  const [video_type, setVideoType] = React.useState("");
  const handleChangeVideoType = (event) => {
    setVideoType(event.target.value);
    console.log("value is:", event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();

    // 👇️ value of input field
    console.log("handleClick 👉️", keywords);
    console.log("handleClick 👉️", video_type);
    functionAssignTabName(0, 1, keywords, video_type);
  };

  const handleChangePage = (e, page_num) => {
    e.preventDefault();
    setValue(global_tab_num);
    console.log("handleChange Tabs 👉️", global_tab_num);
    functionAssignTabName(global_tab_num, page_num, keywords, video_type);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const functionAssignTabName = (newValue, page_num, _keywords, _video_type) => {
    global_tab_num = newValue;
    arr_group_video = [];
    axios
      .post(apiVideoListGroup)
      .then((response) => {
        let data = response.data;
        //assign tab search result
        let search = { video_group: "Search Result" };
        data.push(search);
        setNumTabs(data.length);
        setAssignTabName(
          data.map((d, index) => {
            let group_name = d.video_group == null ? "All Videos" : d.video_group;
            let group_name_str = d.video_group == null ? "" : d.video_group;
            arr_group_video.push(group_name_str);
            return <Tab label={group_name} {...a11yProps(index)} />;
          })
        );

        let _video_group = arr_group_video[newValue] === "All Videos" ? "" : arr_group_video[newValue];
        if (_keywords.length > 0 || _video_type.length > 0) {
          newValue = data.length - 1;
          _video_group = "";
        }
        console.log("arr group video", arr_group_video);
        console.log("New Value of Tab====> ", newValue);
        setValue(newValue);
        console.log("Video Group ===>", _video_group);
        const params = new URLSearchParams();
        params.append("page_num", page_num);
        params.append("keywords", _keywords);
        params.append("video_group", _video_group);
        params.append("video_type", _video_type);

        axios.post(`${apiURL}`, params).then((response) => {
          let medias = response.data.media;
          let total_vid = response.data.detail.total;
          let total_pagination = response.data.detail.total_page;
          console.log(medias);
          setAssignTabPanel(
            data.map((d, index) => {
              // console.log(newValue, index);
              let group_name = d.video_group == null ? "All Videos" : d.video_group;
              if (_keywords.length > 0 || _video_type.length > 0) {
                group_name = "Search Result";
              }
              return (
                <div role="tabpanel" hidden={newValue !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} style={{ width: "100%" }} aria-selected={newValue === index ? true : false}>
                  <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                    <Box sx={{ p: 3, justifyContent: "center", alignItems: "center" }}>
                      <Typography varaint="h3" sx={{ fontSize: "1.2rem", textAlign: "center", p: 2 }}>
                        {group_name}
                      </Typography>

                      {medias.length > 0 && (
                        <Container>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography varaint="subtitle2" sx={{ textAlign: "right", pb: 2 }} color="secondary">
                              Total Videos: {total_vid}
                            </Typography>
                            <Paper />
                            <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1000">
                              <Grid xs={12} sm={12} md={12}>
                                <Grid container spacing={2}>
                                  {medias.map((m) => (
                                    <Grid item style={{ display: "flex" }} xs={12} sm={12} md={6} lg={3}>
                                      <Card>
                                        <CardActionArea href={m.youtube_url} target="_blank">
                                          <Chip
                                            className="chip-times"
                                            label={moment
                                              .utc(m.video_length * 1000)
                                              .format("HH:mm:ss")
                                              .replace(/^0(?:0:0?)?/, "")}
                                            size="small"
                                            sx={{ alignItems: "right", textAlign: "right" }}
                                          />

                                          <CardMedia component="img" image={baseURL + "/assets/youtube/" + m.image_name}></CardMedia>

                                          <CardContent lg={12}>
                                            <Chip className="chip-video" label={m.video_type} color={m.video_type === "upcoming" ? "secondary" : "primary"} size="small" sx={{ alignItems: "right" }} />

                                            <Typography variant="subtitle2" sx={{ color: "grey" }}>
                                              {m.channel} | {moment(m.video_date).format("YYYY-MM-DD")}
                                            </Typography>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                              <Typography gutterBottom variant="subtitle1" component="div">
                                                {m.title}
                                              </Typography>
                                            </Box>
                                            {/*                                           
                                            <Typography variant="body2" color="text.secondary">
                                              {moment
                                                .utc(m.video_length * 1000)
                                                .format("HH:mm:ss")
                                                .replace(/^0(?:0:0?)?/, "")}
                                            </Typography> */}
                                          </CardContent>
                                        </CardActionArea>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </div>

                            <Grid display="flex" justifyContent="center" alignItems="center" sx={{ mt: 6, mb: 6 }}>
                              <div class="pagination">
                                <Pagination count={total_pagination} color="secondary" size="large" onChange={(e, value) => handleChangePage(e, value)} />
                              </div>
                            </Grid>
                          </Box>
                        </Container>
                      )}
                    </Box>
                  </div>
                </div>
              );
            })
          );
        });
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      });
  };

  const setVideoYoutube = (page_num, keywords, video_group, video_type) => {
    const params = new URLSearchParams();
    params.append("page_num", page_num);
    params.append("keywords", keywords);
    params.append("video_group", video_group);
    params.append("video_type", video_type);

    axios.post(`${apiURL}`, params).then((response) => {
      let medias = response.data.media;

      setTotalPage(response.data.detail.total_page);
      setTotalVideo(response.data.detail.total);
      console.log(medias);
      setVideoPost(
        medias.map((media) => {
          let fullUrl = baseURL + "/assets/youtube/" + media.image_name;
          let video_date_fm = moment(media.video_date).format("YYYY-MM-DD");
          return (
            <Grid item style={{ display: "flex" }} xs={12} sm={12} md={6} lg={4}>
              <Card>
                <CardActionArea href={media.youtube_url} target="_blank">
                  <CardMedia component="img" image={fullUrl}></CardMedia>

                  <CardContent lg={12}>
                    <Typography variant="subtitle2" sx={{ color: "grey" }}>
                      {video_date_fm}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography gutterBottom variant="h6" component="div">
                        {media.video_group}
                      </Typography>
                      <Chip className="chip-video" label={media.video_type} color={media.video_type === "upcoming" ? "secondary" : "primary"} size="small" sx={{ alignItems: "right" }} />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {media.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })
      );
    });
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    if (value === index) {
      let _page_num = 1;
      let _keywords = "";
      let _video_group = children === "All Videos" ? "" : children;
      let _video_type = "";
      const params = new URLSearchParams();
      params.append("page_num", _page_num);
      params.append("keywords", _keywords);
      params.append("video_group", _video_group);
      params.append("video_type", _video_type);

      axios.post(apiURL, params).then((response) => {
        console.log(response.data.media);
        setData(response.data.media);
      });

      // console.log(videoComponent);
      let testData = ["a", "b", "c"];
      return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            <Typography>{value}</Typography>
            <Typography>{index}</Typography>
            {dataGlobal.length > 0 && (
              <div>
                {dataGlobal.map((d) => (
                  <p>{d.title}</p>
                ))}
              </div>
            )}
          </Box>
        </div>
      );
    }
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  async function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  AOS.init();
  return (
    <Box>
      <NavBarPart />
      <Box sx={{ backgroundColor: primaryColor }}>
        <Container sx={{ pb: 5 }}>
          {/* Breadcrumb */}
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pt: 2, mb: 4, color: fontColor }}>
            {breadcrumbs}
          </Breadcrumbs>
          <Box>
            {/* Main */}
            <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
              <Typography variant="h4" color={fontColor} sx={{ textAlign: "center" }}>
                Videos
              </Typography>
            </div>
          </Box>
          {/* Search Part */}
          <Container>
            <Box mt={4} mb={4} sx={{ border: "1px solid #f5f5f5", borderRadius: "3px", backgroundColor: "#f5f5f5", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Grid container lg={12} sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
                <Grid item xs={12} sm={12} md={12} lg={5} display="flex" sx={{ pb: 2, padding: "1rem" }}>
                  <TextField id="input-keywords" onChange={handleChange} value={keywords} label="Search Keywords" variant="filled" size="large" color="primary" sx={{ input: { background: "white", opacity: 0.9 }, mt: 2, mb: 2, width: "100%" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} sx={{ pb: 2, padding: "1rem" }}>
                  <FormControl variant="filled" fullWidth="true">
                    <InputLabel id="label-video-type">Type Of Videos</InputLabel>
                    <Select labelId="label-video-type" id="select-video-type" sx={{ backgroundColor: "white" }} onChange={handleChangeVideoType} value={video_type}>
                      {videoType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={2}>
                  <Box mt={3} mb={3}>
                    <Button variant="contained" startIcon={<SearchIcon />} sx={{ backgroundColor: primaryColor }} onClick={handleClick}>
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          {/* Search Part End */}
        </Container>
      </Box>
      {/* {TabsInfo.map((tabInfo, index) => (
        <Typography>{tabInfo}</Typography>
      ))} */}
      {/* Tab Panel */}

      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", height: "fit-content" }}>
        <Tabs orientation="vertical" variant="scrollable" value={value} onChange={(e, value) => handleChangeTab(e, value)} aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: "divider" }} style={tab}>
          {assignTabName}
        </Tabs>

        {assignTabPanel}
      </Box>
    </Box>
  );
}
