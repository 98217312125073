import * as React from "react";
import { Button, Fab, Grid, IconButton, List, ListItem, FileCopyIcon, Menu, MenuItem, Paper, SpeedDial, SpeedDialIcon, Tabs, Tooltip, Typography, Table, TableContainer, MenuPaper, TableHead, TableRow, TableCell, ListItemButton, ListItemText } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Image } from "mui-image";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Stack from "@mui/material/Stack";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { styled } from "@mui/material/styles";

import { CardActionArea } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import AOS from "aos";

//css
import "../../css/errday.css";
import "@splidejs/react-splide/css";
import "aos/dist/aos.css";
import "@fontsource/prompt";
import ScrollButton from "../main/ScrollButton";
import HeadsetIcon from "@mui/icons-material/Headset";

const primaryColor = "#14a8c2";
const secondaryColor = "#d6030a";
const thirdColor = "white";
const iconColor = "#ffee8d";
const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};

const TikTokIcon = ({ color = iconColor }) => {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%" height="100%">
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const fabstyle = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};
const iframe_container = {
  left: 0,
  width: "100%",
  height: "100%",
  position: "relative",
};

const iframe = { top: 0, left: 0, width: "100%", height: "100%", position: "absolute", border: 0, height: "47rem" };
export default function ErrrdayFunction() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  AOS.init();
  return (
    <Grid container spacing={2} style={{ marginTop: "-4rem" }}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor: primaryColor }}>
          <Image src="./assets/doit/banner.jpg" fit="contain" easing="ease-in-out" duration={1200} />
        </Box>

        {/* Social Media Path */}
        <Tabs centered sx={{ backgroundColor: primaryColor }}>
          <Box sx={{ flex: "center", display: "flex", padding: 0, flexDirection: "row" }}>
            <List style={flexContainer}>
              <ListItem>
                {/* INSTAGRAM */}
                <Tooltip title="@333cyj333" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@333cyj333"
                    href="https://www.instagram.com/333cyj333"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                </Tooltip>

                {/* YOUTUBE */}
                <Tooltip title="@YoungjaexArs" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@YoungjaexArs"
                    href="https://www.youtube.com/@YoungjaexArs"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <YouTube />
                  </IconButton>
                </Tooltip>
                {/* Youngjae Official */}
                <Tooltip title="YOUNGJAExArs" placement="top">
                  <IconButton
                    size="small"
                    aria-label="YOUNGJAExArs"
                    href="https://twitter.com/YOUNGJAExArs"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>

                {/* Youngjae Personal Twitter Account */}
                <Tooltip title="@GOTYJ_Ars_Vita" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@GOTYJ_Ars_Vita"
                    href="https://twitter.com/GOTYJ_Ars_Vita"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>

                {/* Tiktok*/}
                <Tooltip title="@YOUNGJAExArs" placement="top">
                  <IconButton
                    size="small"
                    aria-label="@YOUNGJAExArs"
                    href="https://www.tiktok.com/@youngjaexars"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: iconColor,
                    }}
                  >
                    <div style={{ width: "22px", color: iconColor, marginTop: "5px" }}>
                      <TikTokIcon />
                    </div>
                  </IconButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Tabs>
        <Box height={350} sx={{ backgroundColor: primaryColor, paddingTop: "5rem" }}>
          <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              영재(Youngjae)<br></br>1st Full Album 'Do It'
              <br></br> <br></br>2023.11.06 <br></br>6PM (KST) <br></br>
            </Typography>
          </div>
        </Box>

        {/* Slide */}
        <Box sx={{ backgroundColor: primaryColor, paddingTop: "5rem", paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Splide
                aria-label="Youngjae Teaser Image"
                options={{
                  rewind: true,
                  type: "loop",
                  gap: "0.1rem",
                  autoplay: true,
                  pauseOnHover: false,
                  resetProgress: false,
                  fixedHeight: 1200,
                  perPage: 1,
                  autoHeight: false,
                  cover: true,
                  focus: "center",
                }}
              >
                {" "}
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_1_A.jpg" alt="Teaser 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_1_B.jpg" alt="Teaser 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_2_A.jpg" alt="Teaser 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_2_B.jpg" alt="Teaser 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_3_A.jpg" alt="Teaser 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_3_B.jpg" alt="Teaser 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_4_A.jpg" alt="Teaser 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_4_B.jpg" alt="Teaser 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_5_A.jpg" alt="Teaser 1" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_5_B.jpg" alt="Teaser 2" />
                </SplideSlide>{" "}
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_6_A.jpg" alt="Teaser 2" />
                </SplideSlide>{" "}
                <SplideSlide>
                  <img src="./assets/doit/Teaser_Image_6_B.jpg" alt="Teaser 2" />
                </SplideSlide>
                <SplideSlide>
                  <img src="./assets/doit/do_it_youngjae.jpg" alt="Teaser 2" />
                </SplideSlide>
              </Splide>
            </Grid>
          </Grid>
        </Box>
        {/* Listen Now */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "3rem" }}>
          <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              Listen Now
            </Typography>
            <Grid container spacing={2} padding={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "center" }}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://kko.to/f4O3tLUVma" target="_blank">
                    Melon
                  </Button>{" "}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="www.genie.co.kr/X4T1Z2" target="_blank">
                    Genie Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://www.music-flo.com/detail/album/418827657/albumtrack" target="_blank">
                    FLO Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://music.bugs.co.kr/album/4092771" target="_blank">
                    Bugs Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://vibe.naver.com/album/30159104" target="_blank">
                    Naver Vibe
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://open.spotify.com/album/18YFtCJFxZpaI768o4TVnB" target="_blank">
                    Spotify
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://music.youtube.com/playlist?list=OLAK5uy_ngr0dHt6WEj16Saph0LodAo9kpbvJN9Eo" target="_blank">
                    Youtube Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://music.apple.com/us/album/do-it/1714223210" target="_blank">
                    Apple Music
                  </Button>
                </Stack>
              </Grid>
              {/*   <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://music.apple.com/th/album/errr-day-single/1676871247" target="_blank">
                    iTunes Store
                  </Button>
                </Stack>
              </Grid> 

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: iconColor }} href="https://tidal.com/browse/album/281497073" target="_blank">
                    Tidal Music
                  </Button>
                </Stack>
              </Grid>*/}

              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="https://music.apple.com/us/browse" target="_blank">
                    Apple Music
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack spacing={1}>
                  <Button size="large" variant="outlined" startIcon={<HeadsetIcon />} sx={{ color: secondaryColor }} href="#" target="_blank">
                    iTunes Music
                  </Button>
                </Stack>
              </Grid> */}
            </Grid>
          </div>
        </Box>

        {/* Music Video */}
        <Box pt={7} sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Music Video
          </Typography>

          <Grid container spacing={2} padding={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="1280" height="1000" src="https://www.youtube.com/embed/IGiEvRpHcpA" frameborder="0" allowfullscreen allow="autoplay" title="Highlight Medley"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' MV
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} padding={1} justifyContent="center" alignItems="center"></Grid>
        </Box>

        {/* Pre-Order Detail */}
        <Box sx={{ backgroundColor: primaryColor, paddingTop: "7rem", paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
                PRE-ORDER (Blue/Red Ver.)
              </Typography>
              <br></br>
              <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <List sx={{ opacity: 0.95 }} component="nav">
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/Tq31b" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 YES24</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/4nlS2" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 HOTTRACK</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/XBWsg" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 ALADIN</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/VP2To" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 SYNNARA</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://www.ktown4u.com/iteminfo?goods_no=102039" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 KTOWN4U</ListItemText>
                  </ListItemButton>
                </List>
                {/* <TableContainer sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", border: "solid 1px #ccc", ":hover": { background: secondaryColor } }}>
                          <Link href="https://www.yes24.com/Product/Goods/123152655" target="_blank" style={{ color: iconColor, textDecoration: "none", fontSize: "1rem" }}>
                            YES24
                          </Link>
                        </TableCell>
                      </TableRow>{" "}
                      <TableRow>
                        <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", border: "solid 1px #ccc", ":hover": { background: secondaryColor } }}>
                          <Link href="vo.la/4nlS2" target="_blank" style={{ color: iconColor, textDecoration: "none", fontSize: "1rem" }}>
                            HOTTRACK
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", border: "solid 1px #ccc", ":hover": { background: secondaryColor } }}>
                          <Link href="vo.la/XBWsg" target="_blank" style={{ color: iconColor, textDecoration: "none", fontSize: "1rem" }}>
                            ALADIN
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", border: "solid 1px #ccc", ":hover": { background: secondaryColor } }}>
                          <Link href="vo.la/VP2To" target="_blank" style={{ color: iconColor, textDecoration: "none", fontSize: "1rem" }}>
                            SYNNARA
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", border: "solid 1px #ccc", ":hover": { background: secondaryColor } }}>
                          <Link href="#" target="_blank" style={{ color: iconColor, textDecoration: "none", fontSize: "1rem" }}>
                            KTOWN
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer> */}
              </Grid>

              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <img src="./assets/doit/Pre_Order_2.jpg" style={{ maxWidth: "100%", height: "auto", padding: "0", margin: "0" }} alt="Preview Preorder"></img>
                <br></br>
                <img src="./assets/doit/Pre_Order_2_Detail.jpg" style={{ maxWidth: "100%", height: "auto", padding: "0", margin: "0" }} alt="Preview Preorder"></img>
              </div>
              {/* <Typography variant="subtitled">Day Version</Typography> */}
            </Grid>
          </Grid>
        </Box>
        {/* Pre-Order Detail */}
        <Box sx={{ backgroundColor: primaryColor, paddingTop: "7rem", paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
                PRE-ORDER (NEMO Ver.)
              </Typography>
              <br></br>
              <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <List sx={{ opacity: 0.95 }} component="nav">
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/NUU8s" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 YES24</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/x8n4j" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 HOTTRACK</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/s13Fz" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 ALADIN</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://vo.la/spU8s" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 SYNNARA</ListItemText>
                  </ListItemButton>
                  <ListItemButton divider alignItems="flex-start" href="https://www.ktown4u.com/iteminfo?goods_no=102037" target="_blank" sx={{ background: "none", textAlign: "center", justifyContent: "center", alignItems: "center", ":hover": { background: secondaryColor } }}>
                    <ListItemText sx={{ color: iconColor, ":hover": { color: iconColor } }}>💿 KTOWN4U</ListItemText>
                  </ListItemButton>
                </List>
              </Grid>

              <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
                <img src="./assets/doit/Pre_Order_Nemo.jpg" style={{ maxWidth: "100%", height: "auto", padding: "0", margin: "0" }} alt="Preview Preorder"></img>
                <br></br>
                <img src="./assets/doit/Pre_Order_Nemo_2.jpg" style={{ maxWidth: "100%", height: "auto", padding: "0", margin: "0" }} alt="Preview Preorder"></img>
              </div>
              {/* <Typography variant="subtitled">Day Version</Typography> */}
            </Grid>
          </Grid>
        </Box>

        {/* Program TV */}
        <Box pt={7} sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Videos
          </Typography>

          <Grid container spacing={5} padding={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive ">
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/iovkw-do8jY" frameborder="0" allowfullscreen allow="autoplay" title="Awake Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재 'Do It' [MENCERT] GOT7 YOUNGJAE '두 잇'
                </Typography>
              </div>
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="1280" height="1000" src="https://www.youtube.com/embed/U0jQCXkMbkw" frameborder="0" allowfullscreen allow="autoplay" title="Run Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) - 'Do It' Cheering Guide 💚
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive ">
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/1VcnD8dzNGs" frameborder="0" allowfullscreen allow="autoplay" title="Awake Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  [Teaser] 영재(Youngjae)의 저스트텐미닛 레벨업🎤23.11.09. 6PM (KST) COMING SOON
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
        {/* Mood Film Video */}
        <Box pt={7} sx={{ backgroundColor: primaryColor, paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Mood Film Video
          </Typography>

          <Grid container spacing={5} padding={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive">
                  <iframe width="1280" height="1000" src="https://www.youtube.com/embed/7VtmGfy-wXo" frameborder="0" allowfullscreen allow="autoplay" title="Run Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) ‘Do It' Mood Film (Run ver.)
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1800">
                <div className="video-responsive ">
                  <iframe width="1280" height="720" src="https://www.youtube.com/embed/w1-wkaNnP5o" frameborder="0" allowfullscreen allow="autoplay" title="Awake Version"></iframe>
                  <br></br>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) ‘Do It' Mood Film (Awake ver.)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>

        {/* Teaser Video */}
        <Box sx={{ backgroundColor: primaryColor, paddingTop: "5rem", paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Concept Spoiler
          </Typography>

          <Grid container spacing={5} padding={2} style={{ justifyContent: "center", alignItems: "center" }}>
            <Grid container spacing={2} padding={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                  <div className="video-responsive">
                    <iframe width="1280" height="1000" src="https://www.youtube.com/embed/ymtPClUjTWU" frameborder="0" allowfullscreen allow="autoplay" title="Highlight Medley"></iframe>
                    <br></br>
                  </div>
                  <Typography variant="subtitled" style={{ color: "white" }}>
                    영재(Youngjae) 'Do It' Highlight Medley
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                  <div className="video-responsive">
                    <iframe width="1280" height="1000" src="https://www.youtube.com/embed/AVb3TZ5mmRg" frameborder="0" allowfullscreen allow="autoplay" title="Highlight Medley"></iframe>
                    <br></br>
                  </div>
                  <Typography variant="subtitled" style={{ color: "white" }}>
                    영재(Youngjae) 'Do It' MV Teaser
                  </Typography>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div data-aos="fade-up" class="aos-init aos-animate" data-aos-duration="1800">
                  <div className="video-responsive">
                    <iframe width="1280" height="1000" src="https://www.youtube.com/embed/pXEntXqFGsM" frameborder="0" allowfullscreen allow="autoplay" title="Concept Spoiler"></iframe>
                    <br></br>
                  </div>
                  <Typography variant="subtitled" style={{ color: "white" }}>
                    영재(Youngjae) 'Do It' Concept Spoiler
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Tiktok Video */}
        {/* <Box sx={{ backgroundColor: primaryColor, paddingTop: "5rem", paddingBottom: "3rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center", paddingBottom: "2rem" }}>
            Tiktok Video
          </Typography>

          <Grid container spacing={5} padding={2} style={{ justifyContent: "center", alignItems: "center" }}>
            <Grid container spacing={2} padding={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7296052573348302082" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) ‘Do It' Mood Film (Run ver.)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7296052699345341698" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) ‘Do It' Mood Film (Awake ver.)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7296786882518682881" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' Challenge Teaser
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7297157912487644418" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' Challenge 솔로 ver. #영재와_DoItChallenge
                </Typography>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7297157921656294658" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' Challenge 단체 ver. #영재와_DoItChallenge
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7297536910346456322" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' Lyric Teaser
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={iframe_container}>
                  <iframe src="https://www.tiktok.com/embed/7297908211733073153" className={iframe} style={{ height: "36rem" }} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
                </div>
                <Typography variant="subtitled" style={{ color: "white" }}>
                  영재(Youngjae) 'Do It' MV Teaser
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box> */}
        {/* News Part */}
        <Box sx={{ backgroundColor: primaryColor, paddingBottom: "7rem", paddingTop: "5rem" }}>
          <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="2000">
            {" "}
            <Typography variant="h5" sx={{ color: thirdColor, textAlign: "center" }}>
              News
            </Typography>
            <Grid container spacing={4} style={{ display: "flex", padding: "1rem" }}>
              {/* New 1 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/GNvBvHwt" target="_blank">
                    <CardMedia component="img" image="./assets/doit/do_it_youngjae.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        갓세븐 영재, 첫 솔로 정규 앨범 ‘Do It’ 11월 6일 발매
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* New2 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/FLhDjYz9" target="_blank">
                    <CardMedia component="img" image="./assets/doit/schedule_poster.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 첫 솔로 정규 'Do It' 스케줄러..'청량→쓸쓸' 반전
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* New3 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/5v4MtJ4P" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_3.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 ‘Do It’ 콘셉트 스포일러 영상 공개
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* New4 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/GMWv8b2x" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_4.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 'Do It' 콘셉트 포토 공개..필드 위 승부사 비주얼
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>{" "}
              {/* New5 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/x4uuFsfC" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_5.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 'Do It' 콘셉트 포토..시크+청량美 매력
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* New6 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://www.globalepic.co.kr/view.php?ud=202310280821562084d3244b4fed_29 " target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_6.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 트랙리스트 공개…작사와 작곡 직접 참여
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* News7 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/xn6hjNZZ" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_7_1.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 Run 버전 무드 필름 공개..로맨틱 감성
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* News8 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/G4xWrCVe" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_7.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        소년 같은 비주얼…영재, 첫 정규 ‘두 잇’ 향한 기대감↑
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* News9 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/583Np4vm" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_9.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 하이라이트 메들리 공개…능력치 만렙
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>{" "}
              {/* News10 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/5GhpgALC" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_10.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 'Do It' 리릭 티저 오픈..달달한 가을송 예고
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* News11 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/5jmB7nU0" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_11.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 무엇이든 해보자…첫 솔로 정규 ‘Do It’ 발매
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* News12 */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardActionArea href="https://naver.me/GB5vLELd" target="_blank">
                    <CardMedia component="img" image="./assets/doit/news_12.jpg" alt="Naver News" sx={{ maxHeight: 600 }}></CardMedia>

                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        [영재]
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        영재, 정규 1집 아이튠즈 9개지역 톱송&앨범차트 1위
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>

        {/* Speed Dial */}
        {/* <SpeedDial ariaLabel="Go to top" sx={{ position: "absolute", bottom: 16, right: 16 }} icon={<SpeedDialIcon />}></SpeedDial> */}
        {/* End: Speed Dial */}

        {/* Button Go To Top */}
        <ScrollButton />
        {/* End: Button Go To Top */}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
