import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Badge, Box, Breadcrumbs, Button, Card, CardActionArea, CardContent, CardMedia, Chip, CssBaseline, Divider, FormControl, Grid, IconButton, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Paper, Select, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Image } from "mui-image";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AOS from "aos";
import axios from "axios";
import { pink, yellow } from "@mui/material/colors";
// SubMenu
import NavBarPart from "../main/navbar";

//icon menu
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import HeadsetIcon from "@mui/icons-material/Headset";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import VideocamIcon from "@mui/icons-material/Videocam";
import SearchIcon from "@mui/icons-material/Search";
import RadioIcon from "@mui/icons-material/Radio";
//css
import "aos/dist/aos.css";
import "../../css/bestfriend.css";
//constraint
const primaryColor = localStorage.getItem("mode") == "light" ? "#14a8c2" : "#14a8c2";
const secondaryColor = "#d6030a";
const fontColor = localStorage.getItem("mode") == "light" ? "#ffee8d" : "white";

const breadcrumbs = [
  <Link underline="hover" sx={{ display: "flex", alignItems: "center", color: fontColor }} href="/">
    <HomeIcon sx={{ mr: 0.5, color: fontColor }} fontSize="inherit" color={fontColor} />
    Main
  </Link>,
  <Typography key="3" sx={{ color: fontColor }}>
    GOT7 Youngjae's Bestfriend
  </Typography>,
];

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};
const buttonColor = yellow[600];
const baseURL = "https://www.focusonyoungjae.com";
const guestListURL = `${baseURL}/_bestfriend/guestlist`;
const videoListURL = `${baseURL}/_bestfriend/details`;
const videoType = [
  {
    value: "",
    label: "All Type",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "shorts",
    label: "Shorts",
  },
  {
    value: "live",
    label: "Live",
  },
  {
    value: "special",
    label: "Special",
  },
  {
    value: "off air",
    label: "Off Air",
  },
  {
    value: "teaser",
    label: "Teaser",
  },
];

//export function
export default function BestfriendFunction() {
  //call guest list
  const [guestlist, setPost] = React.useState("");
  const [videopost, setVideoPost] = React.useState("");
  const [totalpage, setTotalPage] = React.useState("");
  const [total_videos, setTotalVideo] = React.useState("");
  React.useEffect(() => {
    setVideoBestfriend(1, "true", "", "");
  }, []);

  React.useEffect(() => {
    axios.post(guestListURL).then((response) => {
      let medias = response.data;
      let arr_days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      setPost(
        medias.map((media) => {
          let primary_str = `${media.date}: ${media.corner_name}`;
          let days = media.days;
          let currDate = new Date();
          let current_date = currDate.getFullYear() + "-" + ("0" + (currDate.getMonth() + 1)).slice(-2) + "-" + currDate.getDate();
          let day_num = currDate.getDay();
          let current_day = arr_days[day_num];
          let background_color = localStorage.getItem("mode") == "light" ? "white" : "#111111";
          //background_color = current_day === days ? fontColor : background_color;
          if (current_day == days) {
            background_color = localStorage.getItem("mode") == "light" ? fontColor : "#525252";
          }

          return (
            <ListItemButton divider alignItems="flex-start" href={media.link_url === "#" ? "" : media.link_url} target="_blank" sx={{ backgroundColor: background_color, textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <ListItemAvatar>
                <Avatar src="#">{days.substring(0, 2)}</Avatar>
              </ListItemAvatar>

              <ListItemText sx={{ color: localStorage.getItem("mode") == "light" ? "#656565" : "white" }} primary={primary_str} secondary={media.guest_name}></ListItemText>
              <Typography sx={{ color: localStorage.getItem("mode") == "light" ? primaryColor : "white" }}>{media.broadcast_type !== "" && <VideocamIcon sx={{ fontSize: "2rem" }} />}</Typography>
            </ListItemButton>
          );
        })
      );
    });
  }, []);

  //search function
  const [keywords, setKeywords] = React.useState("");
  const handleChange = (event) => {
    setKeywords(event.target.value);
    console.log("value is:", event.target.value);
  };

  const [video_type, setVideoType] = React.useState("");
  const handleChangeVideoType = (event) => {
    setVideoType(event.target.value);
    console.log("value is:", event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    // 👇️ value of input field
    console.log("handleClick 👉️", keywords);
    console.log("handleClick 👉️", video_type);
    setVideoBestfriend(1, "false", keywords, video_type);
  };

  const handleChanePage = (event, value) => {
    event.preventDefault();
    // 👇️ value of input field
    console.log("handleClick 👉️", value);
    setVideoBestfriend(value, "false", keywords, video_type);
  };

  const setVideoBestfriend = (page_num, initial, keywords, video_type) => {
    const params = new URLSearchParams();
    params.append("page_num", page_num);
    params.append("initial", initial);
    params.append("keywords", keywords);
    params.append("video_type", video_type);

    axios.post(`${videoListURL}`, params).then((response) => {
      let medias = response.data.media;

      setTotalPage(response.data.detail.total_page);
      setTotalVideo(response.data.detail.total);
      console.log(medias);
      setVideoPost(
        medias.map((media) => {
          let fullUrl = baseURL + "/assets/bestfriend/" + media.img_name;
          return (
            <Grid item style={{ display: "flex" }} xs={12} sm={12} md={6} lg={4}>
              <Card>
                <CardActionArea href={media.youtube_url} target="_blank">
                  <CardMedia component="img" image={fullUrl}></CardMedia>

                  <CardContent lg={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography gutterBottom variant="h6" component="div">
                        EP.{media.episode} {media.ep_date}
                      </Typography>
                      <Chip label={media.video_type} color={media.video_type === "upcoming" ? "secondary" : "primary"} size="small" sx={{ alignItems: "right" }} />
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                      {media.ep_name}
                    </Typography>
                  </CardContent>

                  <CardContent lg={12}></CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })
      );
    });
  };
  AOS.init();
  return (
    <Box>
      {/* <NavBarPart /> */}
      <Box sx={{ backgroundColor: primaryColor, marginTop: "-4rem" }}>
        <Container>
          {/* Breadcrumb */}
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pt: 4, mb: 4, color: fontColor }}>
            {breadcrumbs}
          </Breadcrumbs>
          <Box>
            {/* Main */}
            <div data-aos="fade-right" class="aos-init aos-animate" data-aos-duration="1800">
              <Typography variant="h4" color={fontColor} sx={{ textAlign: "center" }}>
                GOT7 영재의 친한친구
              </Typography>
              <Typography variant="subtitle1" color={fontColor} sx={{ textAlign: "center" }} mt={1}>
                GOT7 Youngjae's Bestfriend
                <br></br>MON-FRI at MBC FM4U 12:00AM(KST)
              </Typography>
            </div>
          </Box>

          {/* ICON PART */}
          <Box marginTop={2}>
            <Tabs centered sx={{ backgroundColor: primaryColor }}>
              <Box sx={{ flex: "center", display: "flex", padding: 0, flexDirection: "row" }}>
                <List style={flexContainer}>
                  <ListItem>
                    <Tooltip title="MBC Radio" placement="top">
                      <IconButton
                        size="small"
                        aria-label="GOT7 영재의 친한친구"
                        href="https://www.imbc.com/broad/radio/fm4u/bfever/"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <HomeIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="@mbcbf_ever" placement="top">
                      <IconButton
                        size="small"
                        aria-label="@mbcbf_ever"
                        href="https://twitter.com/mbcbf_ever"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <TwitterIcon />
                      </IconButton>
                    </Tooltip>

                    {/* INSTAGRAM */}
                    <Tooltip title="@mbcbf_ever" placement="top">
                      <IconButton
                        size="small"
                        aria-label="@mbcbf_ever"
                        href="https://www.instagram.com/mbcbf_ever"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <InstagramIcon />
                      </IconButton>
                    </Tooltip>

                    {/* YOUTUBE */}
                    <Tooltip title="@radiombc" placement="top">
                      <IconButton
                        size="small"
                        aria-label="@radiombc"
                        href="https://www.youtube.com/@radiombc"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <YouTube />
                      </IconButton>
                    </Tooltip>

                    {/* iOS */}
                    <Tooltip title="MBC Mini" placement="top">
                      <IconButton
                        size="small"
                        aria-label="MBC Mini"
                        href="https://apps.apple.com/th/app/mbc-mini/id384041016"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <AppleIcon />
                      </IconButton>
                    </Tooltip>

                    {/* Andriod */}
                    <Tooltip title="MBC Mini" placement="top">
                      <IconButton
                        size="small"
                        aria-label="MBC Mini"
                        href="https://play.google.com/store/apps/details?id=com.imbc.mini"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <AndroidIcon />
                      </IconButton>
                    </Tooltip>

                    {/* 다시 듣기 */}
                    <Tooltip title="MBC Podcast (다시 듣기)" placement="top">
                      <IconButton
                        size="small"
                        aria-label="Apple Podcast"
                        href="https://miniwebapp.imbc.com/replay/?program=1005604100000100000"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <RadioIcon />
                      </IconButton>
                    </Tooltip>

                    {/* Podcast */}
                    <Tooltip title="Apple Podcast" placement="top">
                      <IconButton
                        size="small"
                        aria-label="Apple Podcast"
                        href="https://podcasts.apple.com/kr/podcast/got7-%EC%98%81%EC%9E%AC%EC%9D%98-%EC%B9%9C%ED%95%9C%EC%B9%9C%EA%B5%AC/id1616603666"
                        target="_blank"
                        style={{
                          fontSize: "10px",
                          color: fontColor,
                        }}
                      >
                        <PodcastsIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                </List>
              </Box>
            </Tabs>
          </Box>
          {/* ICON PART END */}

          {/* Listen Now Part */}
          <Box alignContent="center" display="flex" sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
            <Button variant="outlined" color="warning" sx={{ color: fontColor, border: "solid 1px white", padding: "1rem", margin: "1rem" }} size="large" startIcon={<HeadsetIcon />} href="https://miniwebapp.imbc.com/index?channel=mfm" target="_blank">
              MBC MINI
            </Button>
            <Button variant="outlined" color="warning" sx={{ color: fontColor, border: "solid 1px white", padding: "1rem", margin: "1rem" }} size="large" startIcon={<HeadsetIcon />} href="https://navernow.onelink.me/o5cK/2dkbeak0" target="_blank">
              NAVER NOW
            </Button>
          </Box>
          {/* Listen Now Part End */}

          {/* Guest List Part */}
          <Box pt={2} pb={4}>
            <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={8} lg={7}>
                <Typography variant="h6" color={fontColor} sx={{ textAlign: "center" }} mt={1} mb={1}>
                  Weekly Guest
                </Typography>
                <List sx={{ opacity: 0.95 }} component="nav" aria-label="mailbox folders">
                  {guestlist}
                </List>
              </Grid>
            </Grid>
          </Box>
          {/* Guest List Part End */}
        </Container>
      </Box>

      {/* Search Part */}
      <Container>
        <Box mt={4} mb={4} sx={{ border: "1px solid #ccc", borderRadius: "20px", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <Grid container lg={12} sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={5} display="flex" sx={{ pb: 2, padding: "1rem" }}>
              <TextField id="input-keywords" onChange={handleChange} value={keywords} label="Search Keywords" variant="filled" size="large" color="primary" sx={{ input: { opacity: 0.9 }, mt: 2, mb: 2, width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} sx={{ pb: 2, padding: "1rem" }}>
              {/* <InputLabel id="demo-simple-select-filled-label">Type Of Video</InputLabel>

              <TextField id="select-video-type" labelId="demo-simple-select-filled-label" select defaultValue="All Type" fullWidth="true">
                {videoType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
              <FormControl variant="filled" fullWidth="true">
                <InputLabel id="label-video-type">Type Of Videos</InputLabel>
                <Select labelId="label-video-type" id="select-video-type" onChange={handleChangeVideoType} value={video_type}>
                  {videoType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={2}>
              <Box>
                <Button variant="contained" startIcon={<SearchIcon />} sx={{ backgroundColor: primaryColor }} onClick={handleClick}>
                  Search
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="subtitle2" sx={{ textAlign: "right", color: "#656565" }}>
          Total Videos {total_videos}
        </Typography>
      </Container>
      <Box></Box>
      {/* Search Part End */}

      {/* Video Part */}
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Paper />
          <div data-aos="fade-left" class="aos-init aos-animate" data-aos-duration="1000">
            <Grid xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                {videopost}
              </Grid>
            </Grid>
          </div>

          <Grid display="flex" justifyContent="center" alignItems="center" sx={{ mt: 6, mb: 6 }}>
            <div class="pagination">
              <Pagination count={totalpage} color="secondary" size="large" onChange={(e, value) => handleChanePage(e, value)} />
            </div>
          </Grid>
        </Box>
      </Container>
      {/* Video Part End */}
    </Box>
  );
}
