import * as React from "react";
import { styled, useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Route, Routes } from "react-router";
import { HashRouter as Router, BrowserRouter, withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import RadioIcon from "@mui/icons-material/Radio";
import YoutubeIcon from "@mui/icons-material/YouTube";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Language from "@mui/icons-material/Language";
import { TextField, MenuItem, Menu, FormGroup, FormControlLabel, Switch } from "@mui/material";
import "../../css/menubar.css";
import MoreVert from "@mui/icons-material/MoreVert";
import { indigo, pink, cyan } from "@mui/material/colors";

//component
import BoxVideo from "../menu/video";
import Comeback from "../menu/comeback";
import BestfriendFunction from "../menu/bestfriend";
import ErrrDay from "../menu/errrday";
import DoItYoungjae from "../menu/doit";
import Schedule from "../menu/schedule";
import Policy from "../menu/policy";
import Footer from "../main/footer";
import { dark } from "@mui/material/styles/createPalette";
import { Calendar } from "@fullcalendar/core";
import Image from "mui-image";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const arrLanguage = [
  {
    value: "EN",
    label: "English",
    selected: "selected",
  },
  {
    value: "TH",
    label: "ไทย",
    selected: "",
  },
];
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent("#fff")}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

let isHideMenu = () => {
  let path = window.location.pathname;
  if (path == "/") {
    return true;
  } else return false;
};
export default function PersistentDrawerLeft() {
  //theme color
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  let theme_mode = localStorage.getItem("mode") == null ? "light" : localStorage.getItem("mode");
  const [mode, setMode] = React.useState(() => theme_mode);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
        //use this line for save in localStorage
        localStorage.setItem("mode", localStorage.getItem("mode") === "light" ? "dark" : "light");
      },
    }),
    []
  );

  //main color
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#0097a7",
          },
          secondary: {
            main: "#ffd54f",
          },
        },
        typography: {
          fontFamily: ["Prompt", "Noto Sans KR"].join(","),
        },
      }),
    [mode]
  );

  const [open, setOpen] = React.useState(false);
  // const colorMode = React.useContext(ColorModeContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuList = [
    { name: "Main", link: "/", icon: <HomeIcon /> },
    // { name: "Youtube", link: "/youtube", icon: <YoutubeIcon /> },
    { name: "Bestfriend", link: "/bestfriend", icon: <RadioIcon /> },
    // { name: "Videos", link: "/videos", icon: <YoutubeIcon /> },
    { name: "Schedules", link: "/schedules", icon: <EventIcon /> },
  ];
  const [language, setLanguage] = React.useState("EN");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  //setting part
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            {/* Navbar */}

            <Toolbar>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: "none" }) }}>
                <MenuIcon />
              </IconButton>
              <Image src="./assets/bestfriend_page/ars_logo.png" fit="contain" height={30} />
              {/* <Typography variant="h6" noWrap component="div">
                Focus On Youngjae
              </Typography> */}

              {/* Language Part */}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {/* <Language sx={{ color: "white", mr: 1, my: 0.8 }} fontSize="small" />
                 <TextField
                  sx={{ color: "white", svg: "white" }}
                  id="select-language"
                  select
                  label=""
                  value={language}
                  onChange={handleChange}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: { color: "white" },
                  }}
                >
                  {arrLanguage.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
                {/* Setting */}
                <IconButton onClick={handleClick} className="icon-more">
                  <MoreVert />
                </IconButton>
                <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} id="positioned-demo-menu" aria-labelledby="positioned-demo-button" placement="bottom-end">
                  <MenuItem>
                    <FormGroup>
                      {/* Color Mode */}
                      <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} checked={theme.palette.mode == "dark" ? true : false} onClick={colorMode.toggleColorMode} />} label={theme.palette.mode === "dark" ? "Dark Mode" : "Light Mode"} />
                    </FormGroup>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {menuList.map((menu, index) => (
                <ListItem key={menu.name} disablePadding>
                  <ListItemButton href={menu.link}>
                    <ListItemIcon>{menu.icon}</ListItemIcon>
                    <ListItemText primary={menu.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
          </Main>
        </Box>
        {/* <MyApp /> */}

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<DoItYoungjae />} />
            <Route path="/bestfriend" element={<BestfriendFunction />} />
            <Route path="/videos" element={<BoxVideo />} />
            <Route path="/errrday" element={<ErrrDay />} />
            <Route path="/schedules" element={<Schedule />} />
            <Route path="/policy" element={<Policy />} />
          </Routes>
        </BrowserRouter>
        <Divider />
        <Footer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
