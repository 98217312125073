import * as React from "react";
import { useState } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Eventcalendar, getJson, toast } from "@mobiscroll/react";
import { Breadcrumbs, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { formatDate } from "@fullcalendar/core"; //formatd date
import axios from "axios";
import moment from "moment";
import momentTz from "moment-timezone";
import Paper from "@mui/material/Paper";

// SubMenu
import NavBarPart from "../main/navbar";
import { Box, Container } from "@mui/system";
import { Link } from "react-router-dom";

//css
import "../../css/schedule.css";

//constraint
const primaryColor = "#14a8c2";
const secondaryColor = "#d6030a";
const fontColor = localStorage.getItem("mode") == "light" ? "#ffee8d" : "white";
const backgroundColor = localStorage.getItem("mode") == "light" ? "white" : "#121212";
const borderColor = localStorage.getItem("mode") == "light" ? "white" : "#121212";
const breadcrumbs = [
  <Link underline="hover" sx={{ textDecoration: "none", display: "flex", alignItems: "center", color: fontColor }} href="/">
    <HomeIcon sx={{ mr: 0.5, color: fontColor }} fontSize="inherit" color={fontColor} />
    Main
  </Link>,
  <Typography key="3" sx={{ color: fontColor }}>
    Schedules
  </Typography>,
];
const baseURL = "https://www.focusonyoungjae.com";
const scheduleURL = `${baseURL}/schedule/list`;
const scheduleTypeURL = `${baseURL}/schedule/type`;

export default function ScheduleFunction() {
  const [scheduleList, setValues] = React.useState("");
  React.useEffect(() => {
    getScheduleList();
  }, []);

  const [scheduleType, setScheduleType] = React.useState("");
  React.useEffect(() => {
    getScheduleType();
  }, []);

  const getScheduleType = () => {
    axios.post(scheduleTypeURL).then((response) => {
      let medias = response.data;
      console.log(medias);
      setScheduleType(
        medias.map((media) => {
          let code = media.schedule_type;
          let code_0 = code.slice(0, 1);
          return (
            <TableCell sx={{ display: "flex" }}>
              <Typography className="fc-event-badge" sx={{ backgroundColor: media.schedule_color, mr: 1 }}>
                {code_0}
              </Typography>
              <Typography>{code}</Typography>
            </TableCell>
          );
        })
      );
    });
  };
  const getScheduleList = () => {
    let result = [];
    axios.post(scheduleURL).then((response) => {
      let medias = response.data;
      // console.log(medias);
      setValues(
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={medias.map((media) => {
            if (media.flag_del === 0 && media.twitter_id === "YOUNGJAE_SNS") {
              let start_date = momentTz.tz(media.date_start, "Asia/Seoul").subtract(2, "hours").utc().toISOString();
              let end_date = momentTz.tz(media.date_end, "Asia/Seoul").subtract(2, "hours").utc().toISOString();
              // console.log(media.date_start, start_date);
              let obj = {
                title: `${media.schedule_name}`,
                description: media.schedule_description.length <= 0 ? "" : "(" + media.schedule_description + ")",
                start: start_date,
                end: end_date,
                allDay: media.is_all_day === 1 ? true : false,
                extendedProps: {
                  schedule_type: media.schedule_type,
                  schedule_color: media.schedule_color,
                  desc_2: media.schedule_description_2,
                },
                // url: media.schedule_url_1 === undefined ? "" : media.schedule_url_1,
              };
              return obj;
            }
          })}
          eventContent={renderEventContent}
          views={{
            day: {
              titleFormat: { month: "long", day: "numeric", weekday: "long" },
            },
          }}
          eventTimeFormat={{ hour: "numeric", minute: "2-digit", meridiem: false }}
          eventDisplay="auto"
          eventClick={showEvent}
          eventColor={secondaryColor}
        />
      );
    });
  };
  const renderEventContent = (info) => {
    let event_start = info.event.allDay === true ? "" : moment(info.event.start).format("h:mmA").replace(/(:00)/g, "");
    let badge = info.event.allDay === true ? "" : `<div class="fc-event-badge" style="background:${info.event.extendedProps.schedule_color}">${info.event.extendedProps.schedule_type}</div>`;
    let type = info.event.extendedProps.schedule_type;
    let code = type.slice(0, 1);

    return {
      html: `
      <span class="fc-event-badge" style="background:${info.event.extendedProps.schedule_color}">${code}</span>
      <div class="fc-event-time">${event_start}</div>
      <div class="fc-event-title">${info.event.title}</div>`,
    };
  };

  const showEvent = (info) => {
    // console.log(info.event.end);
    let event_time = moment(info.event.start).format("h:mmA").replace(/(:00)/g, "");
    let event_start = info.event.allDay === true ? moment(info.event.start).format("YYYY-MM-DD") : event_time;

    let desc_2 = info.event.extendedProps.desc_2;
    if (desc_2.length > 0) desc_2 = " - " + desc_2;
    toast({
      message: event_start + " - " + info.event.title + " " + info.event.extendedProps.description + desc_2,
      fontSize: "20px",
    });
  };

  return (
    <Box mt={-6}>
      <Box sx={{ backgroundColor: primaryColor, pb: 10 }}>
        <Container>
          {/* Breadcrumb */}
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pt: 2, mb: 4, color: fontColor }}>
            {breadcrumbs}
          </Breadcrumbs>
          <Typography variant="h4" color={fontColor} sx={{ textAlign: "center" }} mt={1} mb={3}>
            Schedules
          </Typography>
          {/* <Typography variant="h6" color={fontColor} sx={{ textAlign: "center" }} mt={1} mb={3}>
            스케줄
          </Typography> */}
          <Box sx={{ padding: "1rem", background: backgroundColor }}>{scheduleList}</Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <TableContainer sx={{ width: "100%", background: backgroundColor }}>
              <TableBody>
                <TableRow sx={{ display: "flex" }}>{scheduleType}</TableRow>
              </TableBody>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>
        {eventInfo.timeText} {eventInfo.event.title}
      </b>
    </>
  );
}
