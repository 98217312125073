import * as React from "react";
import { Box } from "@mui/system";

import { Container, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  return (
    <footer>
      <Container maxWidth="lg">
        <Box>
          <Grid container sm={12} xs={12}>
            <Grid item md={4} sm={4} xs={2}>
              <Box>
                <Tooltip title="Youngjae Updates" placement="right" justifyContent="left">
                  <IconButton
                    size="small"
                    aria-label="Youngjae Updates"
                    href="https://twitter.com/YOUNGJAE_SNS"
                    target="_blank"
                    style={{
                      fontSize: "10px",
                      color: "#ccc",
                      margin: "1rem",
                    }}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              <Typography align="center" style={{ fontSize: "12px", color: "#ccc", paddingTop: "1.5rem" }}>
                This website is not affiliate with official account.
              </Typography>
              <Box sx={{ textAlign: "center", mb: 1 }}>
                <Link href="/" underline="hover" sx={{ color: "#777777" }}>
                  Home
                </Link>{" "}
                |{" "}
                <Link href="/policy" underline="hover" sx={{ color: "#777777" }}>
                  Policy
                </Link>
              </Box>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <Box sx={{ textAlign: "right", justifyContent: "end" }}>
                <Typography align="end" style={{ fontSize: "15px", color: "#ccc", padding: "1.5rem" }}>
                  © 2023 오렌지
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  );
}
