import { useState } from "react";
import { Container, Drawer, Fab, FormControlLabel, FormGroup, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, TextField, Toolbar, Typography } from "@mui/material";
import { styled, useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import HomeIcon from "@mui/icons-material/Home";
import RadioIcon from "@mui/icons-material/Radio";
import YoutubeIcon from "@mui/icons-material/YouTube";
import EventIcon from "@mui/icons-material/Event";
import * as React from "react";
import { Box } from "@mui/system";
import Language from "@mui/icons-material/Language";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Image from "mui-image";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
//constant value
const primaryColor = "#14a8c2";
const secondaryColor = "#d6030a";
const fontColor = "white";

//function
const drawerWidth = 240;

const data = [
  { name: "Home", link: "/", icon: <HomeIcon /> },
  { name: "Bestfriend", link: "/bestfriend", icon: <RadioIcon /> },
  { name: "Videos", link: "/videos", icon: <YoutubeIcon /> },
  { name: "Schedule", link: "/schedules", icon: <EventIcon /> },
];
//export default function
export default function useNavbarFunction() {
  const [open, setOpen] = useState(false);

  const getList = () => (
    <div style={{ width: 250 }} onClick={() => setOpen(false)}>
      {data.map((item, index) => (
        <ListItemButton href={item.link}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </div>
  );
  return (
    <Box
      sx={{
        display: "flex",
        direction: "column",
        position: "relative",
        zIndex: 0,
        background: primaryColor,
        padding: "1rem",
      }}
    >
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Link href="#">
              <img src="./assets/bestfriend_page/ars_logo.png" width="60px"></img>
            </Link>
          </Box>

          <Box>
            <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
              {getList()}
            </Drawer>
            <IconButton aria-label="open drawer" onClick={() => setOpen(true)} edge="start" sx={{ mr: 2, ...(open && { display: "none" }), color: "white", fontSize: "2rem" }}>
              <MenuIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
